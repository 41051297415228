
import { mapGetters } from 'vuex';
import recaptchaImportMixin from '@/plugins/reCaptcha';
import dataLayerMixin from '@/plugins/dataLayer.mixin.js';

export default {
  inject: ['$validator'],
  name: 'ContactForm1',
  props: {
    componentId: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  mixins: [recaptchaImportMixin, dataLayerMixin],
  components: {
    MPowerValidateInput: () => import('../MPowerValidateInput'),
  },
  data() {
    return {
      contact: {},
      loading: false,
      isSendSuccess: false,
      isSendFail: false,
      error: '',
    };
  },
  computed: {
    ...mapGetters(['storeInformation']),
    fields() {
      return this.form.fields || [];
    },
  },
  methods: {
    async submit() {
      await this.$validator.validateAll();

      if (this.formIsInvalid) return;

      try {
        this.loading = true;
        this.isSendFail = false;
        const { storeUrl, _id } = this.storeInformation;
        const recaptchaToken = await this.getReCaptchaToken();
        const params = { contact: this.contact, recaptchaToken, storeUrl };
        await this.$api.stores.sendStoreContactForm(_id, this.componentId, params);
        this.onDataLayerSignUp(this.contact, 'contact');
        if (this.form.button.redirect) this.$router.push(`${this.form.button.redirect}`);
        else this.isSendSuccess = true;
      } catch (error) {
        this.error = error?.response?.data?.message || '';
        this.isSendFail = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
